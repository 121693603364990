.pagination_top_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 2em;
  background:#007afe;
  border: 1px solid #cdd5dd;
  margin-top: 2em;
  margin-bottom: 1em;
  justify-content: space-between;
  flex-wrap: wrap;
  //position: absolute;
  //bottom: 2em;
  //width: 97%;
  //position: absolute;
  //top:expression((0-(footer.offsetHeight)+(document.documentElement.clientHeight? 
          //document.documentElement.clientHeight : document.body.clientHeight)+(ignoreMe=)))
  .pagination_sub_container {
    display: flex;
    align-items: center;
    .MuiTypography-root {
      font-size: 18px;
      color: #e6e6ff;
      font-weight: 600;
    }
    .MuiPagination-root {
      .MuiButtonBase-root {
        min-width: 25px;
        height: 25px;
        color: #20639b;
        font-size: 18px;
      }
      .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
        background-color: unset;
        color: #e6e6ff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .jump_to_page {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      color: #e6e6ff;
      font-weight: 600;
    }
    .MuiAutocomplete-root {
      margin-left: 10px;
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          padding-right: 25px;
          padding-top: 2px;
           padding-bottom: 2px;
           background-color: #ffffff;
          .MuiAutocomplete-input{
            min-width: 25px;
            padding: 6.5px 4px 6.5px 6px;
          }
        }
      }
    }
    .MuiAutocomplete-endAdornment {
      .MuiAutocomplete-clearIndicator {
        display: none;
      }
    }
  }
  /* jump to page selection color change */
::selection {
  background: #fff; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #fff; /* Gecko Browsers */
}
}

@media (max-width: 1660px) {
  .pagination_top_container {
    padding: 0.5em 2em;
  }
}
@media (max-width: 567px) {
  .pagination_top_container {
    padding: 0.5em 10px;
  }
}
