@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-278 {
    height: 92vh;
  }
  .h-568 {
    height: 100px;
  }
  .bg {
    background-color:#007afe !important;
  }
  .z-index1{
    z-index:111;
  }
  
}
@layer components{
  .menu_bar{
    @apply flex   h-278 bg-white;
  }
  /* .menu_bar.active{
    @apply flex h-278 ;
  } */
  
  .card{
    @apply  mt-2 mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl z-auto absolute;
  }
  .card_items{
    @apply flex h-10 items-center text-black hover:bg-secondary  hover:rounded gap-4 p-2 cursor-pointer;
  }
  .setup_card{
    @apply flex flex-col transition ml-0  duration-75 gap-3 mx-4 p-4 hover:bg-secondary mt-2 cursor-pointer bg-white rounded-3xl shadow-md overflow-hidden w-40 ;
  }
  .summary{
    @apply cursor-pointer active:bg-secondary p-2 w-32 text-center text-neutral-500;
  }
  .text{
    @apply text-primary border-b-2 border-b-primary;
  }
  .bar_charts{
    @apply  mt-2  bg-white rounded-3xl shadow-md  p-10  text-lg flex shrink w-4/5;
  }
  .card_heading{
    @apply text-primary font-sans font-medium leading-none opacity-70;
  }
 
  .filter_button{
    @apply bg-gray  hover:bg-primary hover:text-white text-xs text-black font-bold py-2 px-3 rounded-full;
  }
  .item.cName{
    @apply text-primary;
  }
  .sidebar_list{
    @apply flex gap-2 items-center py-2 pr-2    m-2 hover:rounded text-black ;
    cursor: context-menu;
  }
  .menu-text{
    @apply text-primary;
  }
  .domainscan_guide{
    @apply text-neutral-500 font-sans text-sm;
  }
  .twitter_inputs{
    @apply self-end text-neutral-500 text-sm font-sans;
  }
  .MuiTableCell-footer{
    @apply bg-primary text-secondary;
  }
}
.flex.justify-between.bg-primary.p-5.items-center.z-ind {
  position: relative;
  z-index: 99;
}

table.table.table-custom {
  width: 100%;
  text-align: center;
}

thead {
  font-size: 15px;
  color: #a6a1a1;
  border-bottom: 1px solid #dbdbdb;
  height: 44px;
}
td img { 
  margin-left: auto;
  margin-right: auto;
}
tr.border-bottom {
  border-bottom: 1px solid #dbdbdb;
}
td {
  height:44px;
}

.menu_bar {
  padding: 0 12px;
}

.tooltip {
  /* position: relative; */
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #ffffff;
  color: rgb(3 3 3);
  text-align: center;
  padding: 3px 6px;
  border-radius: 10px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  position: absolute;
  left: 78%;
  top: 40px;
  visibility: visible;
  box-shadow: 2px 1px 5px -1px black;
  width: 100%;
}

.tooltip:hover .tooltiptext:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 9px;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  border-right: 8px solid transparent;
}

.overflow-visible {
  overflow: visible;
}

.secondary:hover {
  --tw-bg-opacity: 1;
  background-color: #057dff;
  color: white;
  z-index: 999;
  overflow: visible;
}
 
.text-zinc-200{
  position: absolute;
  right: 10px;
}

.defent-list-img{
  height: 70px;
  width: 80px;

}





.piechart_images .w-500 canvas.bar_charts, canvas.pieChart {
  height: 100%;
  max-height: 600px !important;
}

.piechart_images  canvas.bar_charts {
  /* height: 600px !important; */
  margin-top: 0;
}

 
.sidebar_list:hover {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: #007afe;
    color: white;
}
.w-100{
  width: 100%;
}
.min-container{
  min-width: 95%;
}
.min-nav{
  width: 5%;
  max-width: 72px;
}
.max-nav{
  width: 15.2%;
  max-width: 219px;
}
.max-container{
  min-width: 84.8%;
}