.log_in_main{
    display: flex;
    flex-direction: column;
    // padding: 13rem 5rem 0 5rem;
    align-items: center;
    justify-content: center;
    background-color: aliceblue; 
    gap: 5rem;
    .log_in_input{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3rem;
       .log_in_left_heading{
        .log_in_heading{
            .log_in{
                margin: 0;
                //font-size: 3rem;
                font-weight: 600;
                font: 500 40px/53px Montserrat,sans-serif;
            }
            .log_in_tag{
                margin: 0;
                //font-size: 1.5rem;
                font: 400 20px/32px Montserrat,sans-serif;
            }
        }
        .log_in_head_left{
            .log_in_left{
                width: 100%;
            }
        }
       } 
        .log_in_input_right{
            display: flex;
            //justify-content: center;
            flex-direction: column;
            background-color: white;
            justify-content: center;
            padding: 2rem 0;
            gap: 10px;
            border-radius: 27px;
            -moz-box-shadow:    3px 3px 3px 3px #ccc;
            -webkit-box-shadow: 3px 3px 3px 3px #ccc;
            box-shadow:         3px 3px 5px 6px #ccc;
          
            .log_in_input_heading{
                .log_input_text{
                    font-size: 1rem;
                    text-align: center;
                    margin-bottom:10px;
                    font-weight: 600;
                    opacity: .8;
                }
               
                .log_input_tag{
                    text-align: center;
                    margin-bottom:20px;
                    opacity: .8;
                    letter-spacing: 1;
                }
               
                
            }
            .log_input{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 0 4%;
                
                // .MuiInputLabel-outlined{
                //     margin-left: 29px !important;
                //     line-height: 14px !important;
                // }
               .validation_error{
                 color: red;
                 margin:-10px 4%;
                 font-size: 14px;
               }
               
                .log_in_forgot{
                    color: #007afe;
                    font-size: 1rem;
                    align-self: flex-end;
                    cursor: pointer;
                    margin-right: 4%;
                }
                .log_in_btn_box{
                    display: flex;
                    justify-content: center;
                    .log_signin_btn{
                        width: 12rem;
                    }
                }
               .log_in_bottom{
                text-align: center;
                margin: 0;
                span{
                    color: #007afe;
                    cursor: pointer;
                }
               }
               .log_in_bottom1{
                width: 100%; 
                text-align: center; 
                border-bottom: 1px solid #ccc; 
                line-height: 0.7em;
                margin: 10px 0 20px;
                .span{
                    background:#fff; 
                    padding:0 10px; 
                    color: #3e3f42;
                    
                }
               }

            }
        }        
    }
    .log_in_terms{
        text-align: center;
        span{
            color: #007afe;
            cursor: pointer;
        }
    }
}