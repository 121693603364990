.mui_table{
 .MuiPaper-root{
  width: 100%;
  .MuiTable-root {
      .MuiTableHead-root {
        white-space: nowrap;
        .MuiTableRow-root {
          .MuiTableCell-root {
            background-color: #007afe;
            color: white;
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.6px;
          }
        }
      }
      .MuiTableCell-footer{
        display: none;
      }
      .MuiTableBody-root {
            .MuiTableRow-root {
              border-bottom: 2px solid #ffffff;
              &:nth-child(odd) {
                background-color: #f5f5f5;
              }
              &:nth-child(even) {
                background-color: #eaeaea;
              }
              .MuiTableCell-root {
                text-align: center;
                width: 10%;
    
                & > * {
                  font-size: 16px;
                  letter-spacing: 0.48px;
                }
              }
            }
      }
    }
  }
}
.MuiTable-root {
.MuiTableHead-root {
  white-space: nowrap;
  .MuiTableRow-root {
    .MuiTableCell-root {
      background-color: #007afe;
      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.6px;
    }
  }
}
.MuiTableCell-footer{
  display: none;
}
.MuiTableBody-root {
  .MuiTableRow-root {
    border-bottom: 2px solid #ffffff;
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    &:nth-child(even) {
      background-color: #eaeaea;
    }
    .MuiTableCell-root {
      text-align: center;
      width: 10%;

      & > * {
        font-size: 16px;
        letter-spacing: 0.48px;
      }
    }
  }
}
}
@media only screen and (max-width:900px) {
  .mui_table{
    .MuiTableCell-root {
      text-align: center;
      width: 100% !important;
      display: flex;
      // background-color: #007afe;
      // color: white;
    }
  }
}