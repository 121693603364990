.onboarding_main{
    background-color: aliceblue; 
    display: flex;
    align-items: center;
    height: 100vh;
    gap: 3rem;
    justify-content: space-between;
    .onboarding_left{
        padding-left: 10%;
        display: flex;
        gap: .5rem;
        flex-direction: column;
        .verify_link,
        .onboarding_contact_mail,
        .onboarding_mail_verification,
        .onboarding_wishes{
            margin: 0;
        }
        .onboarding_wishes{
               font-size: 2.5rem;
                letter-spacing: normal;
                font-weight: 300 !important;
                
        }
        .onboarding_mail_verification{
            font-size: 3rem;
            font-weight: 500;
            font-family:sans-serif;
             letter-spacing: normal;
             line-height: .9;

        }
        .onboarding_contact_mail,
        .verify_link{
            font-size: 1.5rem;
            font-weight: 300;
            span {
                color: #007afe;
                cursor: pointer;
            }
        }
    }
    .onboarding_right{
        .waiting_image{
            width: 100%;
            height: 500px;
        }
    }
}