 @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
 *{
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  /* padding: 0; */
  /* margin: 0; */
}
.App{
  background-color: #f7f7f7;
  height: fit-content;
}
/* .apps,
.domains,
.products,
.notifications,
.alarms, 
.social_media,
.setup{
  display: flex;
  height: 60vh;  
  align-items: center;
  padding-top: 20px;
  margin-left: 12%;
}  */
/* .alarms.active{
  margin-left: 12%;
} */
/* .active-link{
  background-color: #e6e6ff;
  color: #007afe;
} */
/* .alarms{
  margin-left: 12%;
}
.alarms.active{
  margin-left: 40%;
} */
.justify-around {
  /* justify-content: space-between;
  margin-left: 38px; */
  text-align: center;
}
.piechart_s {
  width: 50%; 
}
.pieChart { 
  border-radius: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} 
.mt-482.piechart_s {
  margin-top: 660px;
}
td {
  word-break: break-all;
  max-width: 113px;
}
.mt-482.piechart_s.mb-5 {
  margin-bottom: 35px;
}
.mt-482.piechart_s.mb-5 {
  width: 40%;
}

.MuiBox-root.css-0 {
  background: white;
  margin-top: 10px;
  margin-left: 80px;
  margin-right: 80px;
  height: 80%;
  padding: 20px;
  border-radius: 4px;
}
.css-79ws1d-MuiModal-root { 
  overflow-y: scroll;
}
.float-layout {
  padding: 5px 5px;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0;
}

.card-container {
  overflow: hidden;
}

.card {
  /* background-color: dodgerblue; */
  color: black;
  min-height: 100%;  
  /* width: 50%; */
  float: right;
}

.card-title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}

.card-desc {
  padding: 10px;
  text-align: left;
  font-size: 18px;
}
 

div.card-image img {
  width: 50%;
  height: auto;
}

/* details modal css */
.details-modal, .modal-wrapper, .modal-img {
  height: 100%;
  width: 100%;
}

.modal-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.modal-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-wrapper .modal-img, 
.modal-wrapper .modal-text-content {
  flex: 0 0 50%;
  padding: 5px;
}

.modal-text-content {
  padding-left: 40px !important;
}

.modal-text-content .content-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}

.details-item span {
  margin-left: 15px;
}

 
@media only screen and (max-width: 37.5em) {
  div.card-image img {
    width: 100%;
    height: auto;
  }
   
  .card-image {
     flex-direction: column;
  } 

  .card {
    /* width: 100%; */
    margin-top: -4px;
  }
}

.w-500 {
  width: 50%;
}
.justify-space-between {
  justify-content: space-between;
}

.container_s {
  display: flex; 
  width: 100%; 
  max-width: 100%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black;
  flex-wrap: wrap;
}


.container_s input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 15px;
}

.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid orange;
  border-radius: 5px;
  background-color: orange;
  white-space: nowrap;
  color: white;
}

.tag button {
  display: flex;
  padding: 4px;
  border-radius: 20px;
  margin-left: 20px; 
  background-color: unset;
  cursor: pointer; 
  color: #000000;
  font-weight: bold;
}
canvas.pieChart {
  padding: 100px !important;
}
@media (max-width: 768px){
  table {
    min-width: 600px;
  }
  .modal-wrapper{
    flex-direction: column;
  }

  .details-modal, .modal-wrapper, .modal-img {
    height: auto;
    width: auto;
  } 

  .css-1dv0nny-MuiAutocomplete-root {
    width: auto !important;
    min-width: 114px;
}

.flex.overflowHidden {
  overflow: scroll !important;
}
.menu_bar {
  padding: 0 5px !important;
}
.css-6eycvs-MuiPaper-root-MuiCard-root { 
  min-width: 0  !important;
  width: 100px  !important; 

}
.w-500 {
  width: 100% !important;
}
.bar_charts {  
   padding:0.5rem  !important;
}
}
/* 
.ml-15.j.pt-1.gap-5 {
  margin-left: 44px;
}

.details-moda1l { 
  background: white;
  padding: 20px; 
  margin-top: 300px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}
.w-500 {
  width: 50%;
}
canvas.pieChart {
  height: 500px !important;
  width: 506px !important;
  margin-top: 0.5rem; 
}
canvas.bar_charts {
  height: 500px !important;
} */
@media only screen and (max-width: 767px) {
  .piechart_images .w-500 canvas.bar_charts, canvas.pieChart {
    height: 100%;
    max-height: 100% !important;
}
canvas.pieChart {
  padding: 0px !important;
}
.piechart_images  canvas.bar_charts {
  height: 100% !important;
  margin-bottom: 20px;
}
.piechart_images {
  display: block;
}
}

.menu_bar {
  padding: 0 12px;
  background: #ffffff;
  box-shadow: 1px 1px 2px 0px silver;
  border-bottom-right-radius: 5px; 
}